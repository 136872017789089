.RentCatalog-Container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 64px; }
.FlatTile {
  border-radius: 8px;
  box-shadow: var(--boxShadow);
  cursor: pointer;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .FlatTile-Info {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    .Title {
      font-size: 18px;
      font-weight: 700; }
    .Price {
      margin-top: 12px;
      span {
        font-size: 16px;
        font-weight: 700; } }
    .Address, .MaxPeople {
      color: var(--darkGray); } } }

@media screen and ( max-width: 576px ) {
  .RentCatalog-Container {
    grid-template-columns: 1fr;
    grid-gap: 24px; } }
