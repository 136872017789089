.Services {
  background-color: var(--background);
  background-repeat: no-repeat;
  background-position: center right; }
.Services-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px; }
.ServiceTile {
  width: 100%;
  padding: 32px;
  background-color: #fff;
  box-shadow: var(--boxShadow);
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-gap: 48px;
  border-radius: 16px;
  .Avatar {
    width: 100%;
    height: 350px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 70%;
      height: 24px;
      position: absolute;
      right: 24px;
      bottom: -24px;
      background-color: var(--lightGray); }
    .Skeleton {
      width: 100%;
      height: 100%;
      border-radius: 24px; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 24px; }
    .Tag {
      display: inline-block;
      padding: 8px 12px;
      color: #fff;
      position: absolute;
      top: 64px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px; } }
  .Body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Title {
      font-size: 32px;
      line-height: 42px;
      font-weight: 500;
      color: var(--accent); }
    .Description {
      font-size: 16px;
      color: var(--black); }
    .Content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;
      .Content-Section {
        font-weight: 700;
        color: var(--accent);
        font-size: 16px; }
      .Content-Info, .HiddenService {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 24px; }
      .ShowAllButton {
        grid-column: 1/-1;
        font-size: 16px;
        font-weight: 500;
        color: var(--accent);
        display: flex;
        align-items: center;
        cursor: pointer;
        .Icon {
          font-size: 18px;
          margin-left: 12px; } } } } }

@media screen and ( max-width: 1024px ) {
  .ServiceTile {
    grid-template-columns: 350px 1fr;
    .Avatar {
      height: 300px;
      img {
        border-radius: 16px; } }
    .Body {
      .Title {
        font-size: 28px;
        line-height: 38px; }
      .Content {
        .Content-Info, .HiddenService {
          grid-template-columns: 1fr; } } } } }

@media screen and ( max-width: 768px ) {
  .ServiceTile {
    grid-template-columns: 1fr;
    .Avatar {
      height: 380px; }
    .Body {
      .Content {
        .Content-Info, .HiddenService {
          grid-template-columns: repeat(2, minmax(0, 1fr)); } } } } }
@media screen and ( max-width: 576px ) {
  .ServiceTile {
    padding: 24px;
    .Avatar {
      height: 250px; }
    .Body {
      grid-gap: 16px;
      .Title {
        font-size: 22px;
        line-height: 32px; }
      .Content {
        grid-gap: 16px;
        .Content-Info, .HiddenService {
          grid-gap: 16px;
          grid-template-columns: 1fr; } } } } }
