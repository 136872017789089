.FlatPopUp {
  min-width: 900px;
  .Map {
    margin-top: 32px;
    iframe {
      border: 0;
      width: 100%;
      height: 300px; } }
  .Slider {
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 350px;
      object-position: center center; } }
  .Bolder {
    font-weight: 400; }
  .InfoBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Title {
      font-size: 24px;
      font-weight: 700; } } }
.FlatPopUp-Container {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-gap: 64px; }
.Address-Container {
  margin-top: 64px;
  .Title {
    font-size: 24px;
    font-weight: 700; } }

@media screen and ( max-width: 992px ) {
  .FlatPopUp {
    min-width: 100%;
    top: 0;
    bottom: 0;
    border-radius: 0px;
    position: fixed;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 16px;
    .Slider {
      width: calc(100vw - 32px); }
    .PopUp-Content {
      overflow-y: scroll; } }
  .FlatPopUp-Container {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Carousel-Arrow {
      display: none; } } }
