.SectionTitle {
  font-size: 42px;
  line-height: 52px;
  font-weight: 500;
  color: var(--black);
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
  .Title {
    position: relative;
    text-align: center; }
  .Decorator {
    width: 12px;
    height: 24px;
    background-color: var(--accent2);
    position: absolute;
    bottom: 10px;
    left: -20px;
    border-radius: 4px; } }
.SectionTitle_theme_white {
  color: #fff;
  .Decorator {
    background-color: #fff; } }

@media screen and ( max-width: 768px ) {
  .SectionTitle {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 42px;
    .Decorator {
      display: none; } } }
