.About {}

.About-Container {
  display: grid;
  grid-template-columns: 1fr 550px;
  grid-gap: 64px;
  .Avatar {
    width: 100%;
    height: 400px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
      object-position: center center;
      position: relative;
      z-index: 5; }
    &::after {
      content: '';
      display: block;
      width: 45%;
      left: -32px;
      bottom: -32px;
      height: 64px;
      background-color: var(--lightGray);
      position: absolute; } } }

.About-Info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-self: start;
  .Title {
    margin-bottom: 0;
    font-size: 48px;
    line-height: 48px;
    color: var(--accent); }
  .Description {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Description-Facts {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }

@media screen and ( max-width: 1200px ) {
  .About-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }
@media screen and ( max-width: 992px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .About-Info {
    .Title {
      font-size: 36px;
      line-height: 46px; } } }

@media screen and ( max-width: 576px ) {
  .About-Container {
    grid-gap: 48px;
    .Avatar {
      height: 280px; } } }
