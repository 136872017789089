.Site-Hero {
  width: 100%;
  height: 600px;
  padding: 0px 64px;
  display: flex;
  align-items: center;
  background-size: auto 450px, auto 600px, 100% auto;
  background-position: top 50% right 64px, top 0px right -100px, bottom left;
  background-repeat: no-repeat, no-repeat, no-repeat;
  .Offer-Rent {
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr; }
  .Offer-Title {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 16px;
    span {
      font-weight: 300;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background-color: var(--accent2);
        border-radius: 4px;
        right: -24px;
        bottom: 12px; } } }
  .Offer-Description {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: var(--accent); }
  .Offer-Buttons {
    margin-top: 24px;
    justify-self: start;
    a {
      &:first-child {
        margin-right: 16px; } } }

  .Benefits {
    justify-self: start;
    display: inline-flex;
    margin-top: 24px;
    margin-bottom: 16px;
    background-color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: var(--boxShadow); }

  .BenefitCounter {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px var(--lightGray) solid;
    font-weight: 700;
    .Counter {
      font-size: 32px;
      color: var(--accent);
      font-weight: 700; }
    .Description {
      text-transform: uppercase;
      color: var(--black);
      font-size: 16px; }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0; } } }

@media screen and ( max-width: 1024px ) {
  .Site-Hero {
    background-size: auto 0px, auto 600px, 100% auto; } }
@media screen and ( max-width: 768px ) {
  .Site-Hero {
    background-position: top 50% right 64px, top 0px right -200px, bottom left; } }
@media screen and ( max-width: 576px ) {
  .Site-Hero {
    width: 100%;
    height: auto;
    padding: 64px 24px;
    background-size: auto 0px, auto 0px, auto 100%;
    background-position: top 50% right 64px, top 0px right -100px, center left;
    .Offer {
      max-width: 100%;
      .Offer-Title {
        font-size: 36px;
        line-height: 46px; }
      .Offer-Description {
        font-size: 18px;
        line-height: 28px; } }
    .BenefitCounter {
      .Counter {
        font-size: 28px; } }
    .Benefits {
      width: 100%;
      justify-self: start;
      display: flex;
      flex-direction: column;
      .BenefitCounter {
        padding-right: 0;
        margin-right: 0;
        border-bottom: 1px var(--lightGray) solid;
        border-right: 0;
        padding-bottom: 8px;
        margin-bottom: 8px; } } } }
