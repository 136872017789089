.Hotels {
  width: 100%;
  background-color: #B5D8D1;
  background-position: center center;
  background-repeat: no-repeat; }
.Hotels-Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 32px; }
.HotelTile {
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 236px 1fr;
  grid-gap: 24px;
  .Avatar {
    width: 100%;
    height: 180px;
    background-color: #fff;
    border-radius: 16px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover;
      object-position: center center; } }
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-self: start;
    .Title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      color: var(--accent); }
    .Site {
      a {
        color: var(--accent); } }
    .Phone {
      a {
        color: var(--black); } } } }
.HotelTile_theme_green {
  background-color: var(--accent);
  color: #fff !important;
  .Title, .Bolder, .InfoLine {
    color: #fff !important; }
  .Site, .Phone {
    a {
      color: #fff !important; } } }

@media screen and ( max-width: 1024px ) {
  .HotelTile {
    grid-template-columns: 1fr;
    .Avatar {
      height: 250px; } } }
@media screen and ( max-width: 576px ) {
  .Hotels-Container {
    grid-template-columns: 1fr; }
  .HotelTile {
    padding: 16px; } }
